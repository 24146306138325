
  import { ROLES_SCHEDULE_ALLOWED } from '@/store/user/state'
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters('user', ['roles']),
  },
})
  export default class ScheduleIndex extends Vue {
  displayStaffSchedule = false

  get tabs () {
    return [
      {
        name: 'Inspecciones',
        target: 'inspection-schedule',
        display: true,
      },
      {
        name: 'Entregas',
        target: 'delivery-schedule',
        display: true,
      },
      {
        name: 'Tareas',
        target: 'staff-schedule',
        display: this.displayStaffSchedule,
      },
    ]
  }

  get shownTabs () {
    return this.tabs.filter(tab => tab.display)
  }

  @Watch('roles', { immediate: true, deep: true })
  onRolesChange (val) {
    this.displayStaffSchedule = val.some(role => ROLES_SCHEDULE_ALLOWED[role.slug])
  }
  }
